angular.module("app")
    .controller("departmentEditController", ["$scope", "$log", "$state", "$stateParams", "Notification", "departmentService", function ($scope, $log, $state, $stateParams, Notification, departmentService) {

        var vm = this;
        $scope.form = {};

        vm.organisation = { type: 'COMPANY' };

        vm.organisationReference = undefined;

        vm.paymentMethods = [];

        vm.pageNotReady = vm.loading = true;
        vm.submitting = vm.searching = false;

        // exposed functions
        vm.validateAbn = validateAbn;
        vm.add = add;


        function init() {
            if (_.isEmpty($stateParams)) {
                vm.pageNotReady = false;
            } else {
                vm.organisationReference = $stateParams.reference;
                departmentService.get(vm.organisationReference)
                    .then(function (result) {
                        vm.organisation = result;
                    })
                    ['finally'](function () {
                    vm.pageNotReady = false;
                });

            }
        }

        init();


        function add () {
            var organisation = angular.copy(vm.organisation);
            vm.submitting = true;
            if(!_.isEmpty(vm.organisationReference)) {
                updateOrganisation(organisation);
            } else {
                addOrganisation(organisation);
            }
        }

        function updateOrganisation(organisation) {
            departmentService.update(vm.organisationReference, organisation)
                .then(function (result) {
                    $state.go("departments");
                })
                ['catch'](function (resp) {
                reportError(resp);
            })
                ['finally'](function () {
                vm.submitting = false;
            });
        }

        function addOrganisation(organisation) {
            departmentService.add(organisation)
                .then(function (result) {
                    $state.go("departments");
                })
                ['catch'](function (resp) {
                reportError(resp);
            })
                ['finally'](function () {
                vm.submitting = false;
            });
        }


        function validateAbn() {
            if (!_.isEmpty(vm.organisation.abn) && vm.organisation.abn.length === 11) {
                departmentService.validateAbnAndGetBusinessName(vm.organisation.abn).then(function (result) {
                    vm.organisation.legalName = result.businessName;
                    vm.organisation.name = vm.organisation.name ? vm.organisation.name : result.businessName;
                    $scope.form.abn.$setValidity("invalidAbn", true);
                }).catch(function (result) {
                    var message = result.data.messages ? result.data.messages[0] : "Please enter a valid ABN.";
                    vm.organisation.legalName = undefined;
                    vm.organisation.name = vm.organisation.name ? vm.organisation.name : undefined;
                    Notification.error({
                        message: message,
                        delay: 5000
                    });
                    $scope.form.abn.$setValidity("invalidAbn", false);
                });
            } else if(!_.isEmpty(vm.organisation.abn) && vm.organisation.abn.length < 11){
                Notification.error({
                    message: "Please enter a valid ABN.",
                    delay: 5000
                });
                $scope.form.abn.$setValidity("invalidAbn", false);
            } else {
                $scope.form.abn.$setValidity("invalidAbn", true);
            }
        }

        function reportError(resp) {
            if (resp.data.messages) {
                _.each(resp.data.messages, function (message) {
                    Notification.error({message: message, delay: 5000});
                });
            } else {
                Notification.error({
                    message: 'There was a problem adding this department, please refresh and try again',
                    delay: 5000
                });
            }
        }

    }]);